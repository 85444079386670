import React, { useState } from 'react';
import { Tabs, Card } from 'tdesign-react';
import CollectionAccountList from '../components/CollectionAccountList';
import CollectionMemberList from '../components/CollectionMemberList';
import PluginDataDebugger from '../components/PluginDataDebugger';

const { TabPanel } = Tabs;

function MemberManagement() {
    const [activeTab, setActiveTab] = useState('activeMember');

    const handleTabChange = (value) => {
        setActiveTab(value);
    };

    return (
        <div style={{ padding: '20px' }}>
            <Card title="会员信息管理" bordered>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    theme="card"
                >
                    <TabPanel value="activeMember" label="活跃会员">
                        <CollectionAccountList />
                    </TabPanel>
                    <TabPanel value="memberManagement" label="会员管理">
                        <CollectionMemberList />
                    </TabPanel>
                    <TabPanel value="pluginDebug" label="插件调试">
                        <PluginDataDebugger />
                    </TabPanel>
                </Tabs>
            </Card>
        </div>
    );
}

export default MemberManagement;