import React, { useState, useEffect, useRef } from 'react';
import { Card, Space, Button } from 'tdesign-react';

function PluginDataDebugger() {
    const [pluginData, setPluginData] = useState('');
    const [dataHistory, setDataHistory] = useState([]);
    const hiddenDataContainerRef = useRef(null);
    
    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach(() => {
                if (hiddenDataContainerRef.current) {
                    const newText = hiddenDataContainerRef.current.innerText;
                    if (newText && newText !== pluginData) {
                        try {
                            const parsedData = JSON.parse(newText);
                            console.log('插件返回数据:', parsedData);
                            setPluginData(newText);
                            setDataHistory(prev => [...prev, {
                                timestamp: new Date().toLocaleString(),
                                data: parsedData
                            }]);
                        } catch (error) {
                            console.error('解析插件数据失败:', error);
                        }
                    }
                }
            });
        });

        const config = { attributes: true, childList: true, subtree: true };

        const checkElement = () => {
            hiddenDataContainerRef.current = document.querySelector('#hiddenDataContainer');
            
            if (hiddenDataContainerRef.current) {
                observer.observe(hiddenDataContainerRef.current, config);
            } else {
                setTimeout(checkElement, 500);
            }
        };
        
        checkElement();
        
        // Cleanup function
        return () => {
            observer.disconnect();
        };
    }, [pluginData]);

    const clearHistory = () => {
        setDataHistory([]);
    };

    return (
        <Card title="插件数据调试器" bordered style={{ marginTop: '20px' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3>最新插件返回数据</h3>
                    <Button theme="danger" variant="outline" onClick={clearHistory}>清除历史</Button>
                </div>
                
                {pluginData ? (
                    <pre style={{ 
                        backgroundColor: '#f5f5f5', 
                        padding: '10px', 
                        borderRadius: '4px',
                        maxHeight: '200px',
                        overflow: 'auto'
                    }}>
                        {JSON.stringify(JSON.parse(pluginData), null, 2)}
                    </pre>
                ) : (
                    <p>等待插件返回数据...</p>
                )}
                
                <h3>历史数据记录 ({dataHistory.length})</h3>
                {dataHistory.length > 0 ? (
                    <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                        {dataHistory.map((item, index) => (
                            <div key={index} style={{ 
                                marginBottom: '10px', 
                                padding: '10px', 
                                backgroundColor: '#f5f5f5',
                                borderRadius: '4px'
                            }}>
                                <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                                    时间: {item.timestamp}
                                </p>
                                <pre style={{ margin: 0 }}>
                                    {JSON.stringify(item.data, null, 2)}
                                </pre>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>暂无历史数据</p>
                )}
            </Space>
        </Card>
    );
}

export default PluginDataDebugger;