// src/components/AnalysisCommentTable.js
import React, {useEffect, useState, useRef, useMemo} from 'react';
import {
    PrimaryTable,
    Tag,
    Space,
    Dropdown,
    MessagePlugin,
    Row,
    Col,
    Progress,
    Pagination
} from 'tdesign-react';
import {batchUpdateMarketingUserApi, getAnalysisProgressApi, getCommentListByTaskIdApi, getXhsApi} from '../api/api';

const AnalysisCommentTable = ({
                                  taskId,  // 当前任务ID
                                  platform, // 平台信息
                                  userSubscribeInfo, // 用户订阅信息
                                  activeMode, // 分析模式
                                  setAllComments // 设置所有评论
                              }) => {
    // 内部状态管理
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [commentsTotal, setCommentsTotal] = useState(0);
    const [comments, setComments] = useState([]);
    
    const [loading, setLoading] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const selectedRowKeysRef = useRef([]);
    useEffect(() => {
        selectedRowKeysRef.current = selectedRowKeys;
    }, [selectedRowKeys]);

    const [progress, setProgress] = useState(0);
    const [progressLabel, setProgressLabel] = useState("");
    const [clientNum, setClientNum] = useState(0);
    const filterValueRef = useRef({
        评论内容: '',
        用户昵称: '',
        IP地址: '',
        intent_customer: [],
        情绪分析: []
    });


    
    // 在组件内部，但在 return 语句之前添加
    const column_simple = [
        {title: '序号', colKey: 'index', width: "90px"},
        {title: '评论时间', colKey: '评论时间', width: "120px"},
        {
            title: '用户昵称',
            colKey: '用户昵称',
            width: "150px",
            filter: {
                type: 'input',
                resetValue: '',
                confirmEvents: ['onEnter'],
                props: {
                    placeholder: '输入昵称关键词',
                },
                showConfirmAndReset: true,
            }
        },
        {
            title: '用户省份',
            colKey: 'IP地址',
            width: "120px",
            filter: {
                type: 'input',
                resetValue: '',
                confirmEvents: ['onEnter'],
                props: {
                    placeholder: '输入省份关键词',
                },
                showConfirmAndReset: true,
            }
        },
        {
            title: '评论内容',
            colKey: '评论内容',
            filter: {
                type: 'input',
                resetValue: '',
                confirmEvents: ['onEnter'],
                props: {
                    placeholder: '输入关键词过滤',
                },
                showConfirmAndReset: true,
            },
            width: "300px"
        },
        {title: '分析理由', colKey: '分析理由', width: "300px"},
        {title: '全选', colKey: 'row-select', type: 'multiple', width: 46},
        {
            title: '意向客户',
            colKey: 'intent_customer',
            filter: {
                type: 'single',  // 改为single类型
                resetValue: '',  // 重置值改为空字符串
                list: [
                    {label: '全部', value: ''},  // 添加全部选项
                    {label: '高意向', value: '是'},
                    {label: '潜在客', value: '不确定'},
                    {label: '无意向', value: '否'}
                ],
                showConfirmAndReset: true,
            },
            width: "120px"
        }
    ];

    const column_full = column_simple
        .filter(column => column.colKey !== '分析理由')
        .concat([
            {
                title: '情绪分析',
                colKey: '情绪分析',
                width: "120px",
                filter: {
                    type: 'multiple',
                    resetValue: [],
                    list: [
                        {label: '全选', checkAll: true},
                        {label: '中性', value: '中性'},
                        {label: '正向', value: '正向'},
                        {label: '负向', value: '负向'}
                    ],
                    showConfirmAndReset: true,
                }
            },
            {title: '提及产品', colKey: '提及产品', width: "100px"},
            {title: '满意点', colKey: '满意点', width: "120px"},
            {title: '不满点', colKey: '不满点', width: "120px"},
            {title: '疑问点', colKey: '疑问点', width: "120px"}
        ]);


    // 添加获取进度的函数
    const fetchProgress = async () => {
        if (!taskId) return;

        try {
            const data = await getAnalysisProgressApi(taskId);
            if (data.msg === "success") {
                const label = `进度：${data.data.num} / ${data.data.sum}`;
                setProgressLabel(label);

                let percentage = 0;
                if (data.data.sum !== 0) {
                    percentage = parseFloat(((data.data.num / data.data.sum) * 100).toFixed(2));
                }
                setProgress(percentage);
                setClientNum(data.data.ic_num);
            } else {
                setProgress(0);
                setClientNum(0);
                setProgressLabel("0 / ");
            }
        } catch (error) {
            console.error('Failed to fetch progress:', error);
        }
    };

    useEffect(() => {
        fetchProgress();
        const intervalId = setInterval(fetchProgress, 5000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [taskId]);

    // 在组件内添加 handleLinkClick 函数定义
    const handleLinkClick = async (e, originalLink) => {
        e.preventDefault();
        const match = originalLink.match(/explore\/([^/?]+)/);
        const id = match ? match[1] : null;
        if (id) {
            const response = await getXhsApi(id);
            if (response.code === 200 && response.data !== null) {
                const xhsLink = 'https://www.xiaohongshu.com/explore/' + response.data.explore + "?xsec_token=" + response.data.xsec_token + '&xsec_source=pc_search'
                window.open(xhsLink, '_blank');
            }
        } else {
            console.error("无法提取链接中的ID");
        }
    };

    // 获取评论数据
    const fetchComments = async (filters = {}, currentPage = 1, pageSize = 10) => {
        if (!taskId) return;

        setLoading(true);
        try {
            // 清空旧数据，重新加载当前页的数据
            setComments([]);

            const data = await getCommentListByTaskIdApi(taskId, currentPage, pageSize, filters);

            if (data?.data) {
                // 只更新当前页的数据
                setComments(data.data.comment_list || []);
                
                // 检查是否有过滤条件
                const hasFilters = Object.values(filters).some(value => {
                    if (Array.isArray(value)) {
                        return value.length > 0;
                    }
                    return value && value !== '';
                });
                
                // 如果有过滤条件，使用filters_total作为总数
                // 如果API返回了filters_total字段
                if (hasFilters && data.data.filters_total !== undefined) {
                    setCommentsTotal(data.data.filters_total);
                } else {
                    setCommentsTotal(data.data.total);
                }
            }
        } catch (error) {
            console.error('Failed to fetch comments:', error);
            MessagePlugin.error('获取评论失败');
        } finally {
            setLoading(false);
        }
    };



    const fetchAllcomments=async(currentPage=1,pagesize=10,filters={})=>{
        const data = await getCommentListByTaskIdApi(
            taskId,
            currentPage,
            pageSize,
            filters
        );
        if(data?.data){
            if(data.data.total>10){
                const sumData=await  getCommentListByTaskIdApi(
                    taskId,
                    currentPage,
                    data.data.total,
                    filters
                );
                setAllComments(sumData.data.comment_list || []);
            }else{
                setAllComments(data.data.comment_list || []);
            }
        }
    }



    // 处理分页变化
    const handlePageChange = (pageInfo) => {
        setCurrentPage(pageInfo.current);
        setPageSize(pageInfo.pageSize);
        const backendFilters = {
            content: filterValueRef.current.评论内容 || '',
            nickname: filterValueRef.current.用户昵称 || '',
            ip_location: filterValueRef.current.IP地址 || '',
            intent_customer: filterValueRef.current.intent_customer || '',
            emotion: filterValueRef.current.情绪分析?.length > 0 ? filterValueRef.current.情绪分析 : [],
        };
        fetchComments(backendFilters, pageInfo.current, pageInfo.pageSize);
    };

    // 处理过滤条件变化
    const onFilterChange = (filters) => {
        filterValueRef.current = {
            ...filterValueRef.current,
            ...filters,
        };

        // 重置到第一页
        setCurrentPage(1);

        // 构建后端过滤参数
        const backendFilters = {
            content: filterValueRef.current.评论内容 || '',
            nickname: filterValueRef.current.用户昵称 || '',
            ip_location: filterValueRef.current.IP地址 || '',
            intent_customer: filterValueRef.current.intent_customer || '',  // 直接使用单个值
            emotion: filterValueRef.current.情绪分析?.length > 0 ? filterValueRef.current.情绪分析 : [],
        };

        fetchComments(backendFilters, 1, pageSize);
    };

    // 处理意向更新
    const handleIntentChange = async (option, comment) => {
        const currentSelectedKeys = selectedRowKeysRef.current;
        console.log('Current selected keys:', currentSelectedKeys);
        if (currentSelectedKeys.length === 0) return;

        try {
            setLoading(true);
            const comments_data = currentSelectedKeys.map(id => ({
                id,
                intent_customer: option.value,
            }));

            const result = await batchUpdateMarketingUserApi({
                platform: platform.current,
                comments_data
            });

            if (result.status === 200) {
                MessagePlugin.success('批量更新成功');
                setSelectedRowKeys([]);
                fetchComments();
            }
        } catch (error) {
            console.error('批量更新失败:', error);
            MessagePlugin.error('批量更新失败');
        } finally {
            setLoading(false);
        }
    };

    // 初始加载和分页变化时获取数据
    useEffect(() => {
        setCurrentPage(1);
        filterValueRef.current={
            评论内容: '',
            用户昵称: '',
            IP地址: '',
            intent_customer: [],
            情绪分析: []
        }
        fetchComments();
        fetchAllcomments()
        // eslint-disable-next-line
    }, [taskId]);

    // 处理评论展示
    const processedComments = useMemo(() => {
        return comments.map((comment, index) => {
            // 计算每页的起始索引
            const pageIndex = (currentPage - 1) * pageSize + index + 1;

            // 处理评论内容和用户昵称链接
            let commentContent = platform.current === 'xhs'
                ? <a href={`#${index}`} onClick={(e) => handleLinkClick(e, comment.内容链接)}>{comment.评论内容}</a>
                : <a href={comment.内容链接} target="_blank" rel="noreferrer">{comment.评论内容}</a>;

            let userNickname = <a href={comment.用户链接} target="_blank" rel="noreferrer">{comment.用户昵称}</a>;

            // 处理试用会员限制
            if (userSubscribeInfo.package_type === '试用会员') {
                commentContent = <span>{comment.评论内容}</span>;
                userNickname = <span>{comment.用户昵称}</span>;
            }

            return {
                index: pageIndex, // 更新 index 的计算方式
                评论时间: comment.评论时间,
                用户昵称: userNickname,
                IP地址: comment.IP地址,
                评论内容: commentContent,
                intent_customer: comment.intent_customer ? (
                    <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                        <Dropdown
                            options={[
                                {content: '高意向', value: '是'},
                                {content: '潜在客', value: '不确定'},
                                {content: '无意向', value: '否'}
                            ]}
                            onClick={(option) => handleIntentChange(option, comment)}>
                            <Tag
                                theme={comment.intent_customer === "是" ? "success" : comment.intent_customer === "不确定" ? "warning" : "default"}
                                variant={comment.intent_customer === "是" ? 'dark' : 'outline'}>
                                {comment.intent_customer === "是" ? "高意向" : comment.intent_customer === "不确定" ? "潜在客" : "无意向"}
                            </Tag>
                        </Dropdown>
                    </div>
                ) : '',
                分析理由: comment.分析理由 || '',
                id: comment.id,
                comment_id: comment.comment_id
            };
        });
        // eslint-disable-next-line
    }, [comments, currentPage, pageSize, platform.current, userSubscribeInfo.package_type]);



    return (
        <Space direction="vertical" style={{position: 'relative'}}>
            <Row style={{ width: '100%', alignItems: 'center' }}>
                <Col>
                    {progressLabel}
                </Col>
                <Col flex="200px">
                    <Progress
                        label
                        percentage={progress}
                        theme="line"
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                    />
                </Col>
                <Col>
                    意向：{clientNum} 个，占比：{commentsTotal === 0 ? 0 : (clientNum / commentsTotal * 100).toFixed(0)} %
                </Col>
                <Col flex="auto" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {commentsTotal > 0 && (
                        <div className="pagination-container" style={{ marginRight: '10px' }}>
                            <Pagination
                                totalContent={true}
                                theme="default"
                                showPreviousAndNextBtn
                                total={commentsTotal}
                                current={currentPage}
                                pageSize={pageSize}
                                showPageSize={false}
                                onChange={handlePageChange}
                            />
                        </div>
                    )}
                </Col>
            </Row>
            <PrimaryTable
                style={{marginBottom: "10px", overflowX: 'auto', maxWidth: '84vw'}}
                columns={activeMode === '1' ? column_simple : column_full}
                data={processedComments}
                rowKey="id"
                loading={loading}
                pagination={false}
                selectedRowKeys={selectedRowKeys}
                onSelectChange={setSelectedRowKeys}
                filterValue={filterValueRef.current}
                onFilterChange={onFilterChange}
            />
        </Space>
    );
};

export default AnalysisCommentTable;
