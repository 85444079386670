import React, {useEffect} from 'react';
import {Input, InputAdornment, Button, Row, Space, MessagePlugin} from 'tdesign-react';
import dyIcon from "../public/dy.svg";
import xhsIcon from "../public/xhs.svg";
import {getTaskIdAnalysisResultApi} from "../api/api";

const AnalysisHeader = ({
                            commentsTotal,
                            currentKeyWord,
                            currentPlatform,
                            inputValues,
                            currentTaskId,
                            handleInputChange,
                            progress,
                            handleAnalysis,
                            analysisState,
                            analysisAble,
                            activeMode,
                            taskList,
                            setTaskList,
                            SelectedReply
                        }) => {
    const handleAnalysisClick = () => {
        handleAnalysis(currentTaskId);
    };

    const handleReplyClick = () => {
        if (typeof SelectedReply === 'function') {
            SelectedReply();
        } else {
            MessagePlugin.error('私信功能暂不可用');
        }
    };

    const handleDownload = () => {
        getTaskIdAnalysisResultApi(currentTaskId).then(data => {
            if (data.data.url) {
                window.open(data.data.url, '_blank');
            }
        });
    };

    const platformIconMap = {
        'dy': <img src={dyIcon} alt="Prefix Icon" style={{width: '24px', marginTop: '16px'}}/>,
        'xhs': <img src={xhsIcon} alt="Prefix Icon" style={{width: '24px', marginTop: '16px'}}/>
    };

    useEffect(() => {
        const changeTaskList = taskList.map((item) => {
            if (item.task_id === currentTaskId) {
                return {
                    ...item,
                    analysis_state: analysisState
                }
            }
            return item;
        });
        setTaskList(changeTaskList);
    }, [analysisState]);

    return (
        <Row style={{width: "100%"}}>
            <Space align={"center"}>
                {commentsTotal === 0 ? (
                    <h2>暂未收集评论</h2>
                ) : (
                    <Row>
                        {platformIconMap[currentPlatform]}<h2>&nbsp;{currentKeyWord}&nbsp;{commentsTotal}条</h2>
                    </Row>
                )}
                <InputAdornment prepend="我的产品/服务/品牌是" style={{width: "420px", marginLeft: "10px"}}>
                    <Input
                        placeholder="请输入"
                        value={inputValues[currentTaskId] || ''}
                        onChange={(value) => handleInputChange(currentTaskId, value)}
                    />
                </InputAdornment>

                {/* 根据分析状态显示不同的按钮 */}
                {analysisState === "finish" ? (
                    // 分析完成后显示两个按钮
                    <Space>
                        <Button
                            style={{marginLeft: "10px"}}
                            onClick={handleReplyClick}
                            disabled={!analysisAble || commentsTotal === 0}
                        >
                            前往私信
                        </Button>
                        <Button
                            style={{marginLeft: "10px"}}
                            onClick={handleDownload}
                            disabled={!analysisAble || commentsTotal === 0}
                        >
                            下载结果
                        </Button>
                    </Space>
                ) : (
                    // 分析未完成时显示分析按钮
                    <Button
                        style={{marginLeft: "10px"}}
                        onClick={handleAnalysisClick}
                        disabled={!analysisAble || commentsTotal === 0}
                    >
                        {analysisState === 'initial' ? '开始分析' : '停止分析'}
                    </Button>
                )}
            </Space>
        </Row>
    );
};

export default AnalysisHeader;